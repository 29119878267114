import React from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const PrivacyPolicyPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Meta site={meta} title="Privacy Policy" />
        <div id="privacy-policy-section">
          <Container>
            <div className="col-lg-12">
            <h1 className="text-white">Privacy Policy </h1>

            <p className="text-white"><strong>Integrated Specialty Coverages, GmbH (ISC)</strong> respects your privacy and is committed to protecting your personal information. This privacy notice will inform you as to how we look after your personal information and tell you about your privacy rights and how the law protects you. This notice applies to you only if you are located in the United Kingdom, a member state of the European Union, or the European Economic Area.</p>
    
    <p className="text-white"><u>1. IMPORTANT INFORMATION</u></p>
    <p className="text-white">This privacy notice describes how we collect and use personal information about you during and after your relationship with us, in accordance with applicable data protection laws, including the General Data Protection Regulation (GDPR).</p>
    
    <p className="text-white">It applies to:</p>
    <ul>
        <li>Individuals with whom ISC may have had contact for business purposes, either on our own account or on behalf of third parties or organizations;</li>
        <li>Individuals who are involved in transactions or potential transactions which are evaluated or conducted by ISC;</li>
        <li>Individuals who have opted to receive communications from ISC.</li>
    </ul>
    
    <p className="text-white">This website is not intended for children and we do not knowingly collect data relating to children.</p>
    
    <p className="text-white"><u>About ISC</u></p>
    <p className="text-white">This privacy notice is issued on behalf of ISC and its affiliated entities. When we mention “ISC”, “we”, “us” or “our” in this privacy notice, we are referring to the relevant entity in the ISC family responsible for processing your personal information.</p>
    
    <p className="text-white">Unless otherwise specified, the designated contact entity in relation to ISC matters in the EU and EEA is Integrated Specialty Coverages, LLC, a US company. You may contact us at <a href="mailto:legal@iscmga.com">legal@iscmga.com</a>.</p>
    
    <p className="text-white">You have the right to make a complaint at any time to the relevant data protection authority (for details, please click below):</p>
    <ul>
        <li>United Kingdom: <a href="https://www.ico.org.uk" target="_blank">Information Commissioner’s Office (ICO)</a></li>
        <li>Republic of Ireland: <a href="https://www.dataprotection.ie" target="_blank">Data Protection Commissioner (DPC)</a></li>
        <li>Luxembourg: <a href="https://cnpd.public.lu" target="_blank">National Commission for Data Protection (CNPD)</a></li>
        <li>Spain: <a href="http://www.agpd.es" target="_blank">Spanish Data Protection Agency (AEPD)</a></li>
        <li>France: <a href="https://www.cnil.fr" target="_blank">Commission Nationale de l'Informatique et des Libertés (CNIL)</a></li>
        <li>Germany: <a href="https://www.datenschultz.hessen.de" target="_blank">Der Hassische Beauftragte für Datenschutz und Informationsfreiheit (HBDI)</a></li>
    </ul>
    
    <p className="text-white">It is important that you read this notice, together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal information about you.</p>
    
    <p className="text-white"><u>2. DATA PROTECTION PRINCIPLES</u></p>
    <p className="text-white">We will comply with applicable data protection law, which states that the personal information we hold about you must be:</p>
    <ul>
        <li>Used lawfully, fairly, and in a transparent way;</li>
        <li>Collected only for valid purposes and not used in any way that is incompatible with those purposes;</li>
        <li>Relevant to the purposes we have told you about and limited only to those purposes;</li>
        <li>Accurate and, where necessary, kept up to date;</li>
        <li>Kept only as long as necessary for the purposes we have told you about;</li>
        <li>Kept securely.</li>
    </ul>
    
    <p className="text-white"><u>3. DATA SHARING</u></p>
    <p className="text-white">We seek to share your personal information with third parties only where necessary or consistent with our legitimate interests, including third-party service providers and other companies in the ISC family.</p>
    
    <p className="text-white"><u>4. COOKIES</u></p>
    <p className="text-white">ISC may place a cookie on your device and/or browser to collect information about your online activities. You can opt out of non-essential cookies via email at <a href="mailto:legal@iscmga.com">legal@iscmga.com</a>. You may also block cookies via your browser settings.</p>
    
    <p className="text-white"><u>5. YOUR RIGHTS</u></p>
    <p className="text-white">Under applicable data protection law, you have the right to:</p>
    <ul>
        <li>Request access to your personal information.</li>
        <li>Request correction or erasure of your personal information.</li>
        <li>Object to processing of your personal information.</li>
        <li>Request restriction of processing.</li>
        <li>Request transfer of your personal information.</li>
    </ul>
    <p className="text-white">If you wish to exercise any of these rights, please contact us at <a href="mailto:legal@iscmga.com">legal@iscmga.com</a>.</p>
    
    <p className="text-white">This privacy notice may be updated from time to time. We encourage you to review this page regularly for any changes.</p>

			
			<h1 className="text-white">Cancellation Policy </h1>
            <p className="text-white">Canellation and refund of premium paid is only allowed before the plan becomes effective. After the effective date, there is no cancellation or refund of the premium & associated fees. This applies to all plans offered by INFPlans.com Insurance</p>
            <p className="text-white">In order to qualify for a policy cancellation, please complete the cancellation form before the plan effective date. See the form here: https://infplans.com/policy_pdf/INF_Cancellation_Form.pdf</p>
            <p className="text-white">Changes to any INF Program must be sent in writing to support@infplans.com, and must be done before the plan becomes effective.</p>
            </div>
          </Container>
        </div>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query PrivacyPolicyPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        adsense
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    hero_background: file(name: { eq: "bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
